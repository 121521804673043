.App {
  text-align: center;
}

/*
.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}
*/

.App-header {
  /*background-color: #222;*/
  /*height: 75px;*/
  padding: 10px;
  color: #00D;
  font-family: Arial, sans-serif;
}

.App-title {
  /*font-size: 1.5em;*/
  font-size: 3.5em;
  margin: 0.3em;
  
}
.App-title-link {
  /*font-size: 1.5em;*/
  text-decoration: none;
  color: inherit;
}
.main-div, .ReactGridGallery, .gallery-container {
  margin: 0 auto;
  width: 100%;
}

/*
.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}
*/
